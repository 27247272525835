import cookies from "general/cookies";
import { findParentTag } from "next/common";

document.addEventListener("DOMContentLoaded", function () {
  customElements.define(
    "m-menu",
    class extends HTMLElement {
      #initialized = false;
      #boundClose;

      constructor() {
        super();
        this.#boundClose = this.close.bind(this);
      }

      connectedCallback() {
        if (!this.#initialized) {
          // Bind click to trigger slot
          this.querySelector('[slot="trigger"]')?.addEventListener(
            "click",
            (e) => (this.open = !this.open),
          );
          this.#initialized = true;
        }

        // Close menu if user clicks outside of a menu or navigates away
        document.body.addEventListener("click", this.#boundClose);
        window.addEventListener("popstate", this.#boundClose);
      }

      disconnectedCallback() {
        document.body.removeEventListener("click", this.#boundClose);
        window.removeEventListener("popstate", this.#boundClose);
      }

      close(e) {
        if ((e && e.type === "popstate") || !this.contains(e.target)) {
          this.open = false;
        }
      }

      static get observedAttributes() {
        return ["open"];
      }

      attributeChangedCallback(name, oldVal, newVal) {
        if (name === "open") this.dispatchEvent(new CustomEvent("toggle"));
      }

      get open() {
        return this.hasAttribute("open");
      }

      set open(isOpen) {
        isOpen ? this.setAttribute("open", "") : this.removeAttribute("open");
      }
    },
  );

  customElements.define(
    "rm-loader",
    class extends HTMLElement {
      connectedCallback() {
        let loader = this;
        if (
          loader.parentElement.tagName == "BUTTON" ||
          (loader.parentElement.tagName == "A" &&
            loader.parentElement.role == "button")
        ) {
          let button = loader.parentElement;
          button.addEventListener("click", (event) => {
              if (!button.hasAttribute("disabled")) {
                loader.toggleAttribute("loading");
              }
            });

          window.addEventListener("pageshow", (event) => {
            loader.removeAttribute("loading");
          });
        }
      }
    },
  );

  customElements.define(
    "cookie-consent",
    class extends HTMLElement {
      connectedCallback() {
        if (this.getAttribute('visible') != null) {
          this
            .querySelector("button")
            .addEventListener("click", () => {
              this.removeAttribute("visible");
              cookies.set("cookieConsentOpt", "true", 30);
            });
        }
      }
    },
  );

  (function () {
    let elem = document.querySelector("unsupported-browser");
    if (elem && /Trident\/|MSIE/.test(window.navigator.userAgent)) {
      elem.style.display = "block";
    }
  })();

customElements.define(
  "fake-link",
  class extends HTMLElement {
    connectedCallback() {
      if (this.getAttribute("href")) {
        this.addEventListener("click", () => {
          event.preventDefault();
          event.stopPropagation();
          if (event.ctrlKey || event.metaKey) {
            window.open(this.getAttribute("href"), "_blank");
          } else {
            window.location = this.getAttribute("href");
          }
        });
        this.addEventListener("contextmenu", () => {
          event.preventDefault();
          event.stopPropagation();
          window.open(this.getAttribute("href"), "_blank");
        });
      }
    }
  },
);

  const cookieConsent = document.querySelector("cookie-consent[visible]");
  if (cookieConsent) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            cookieConsent.style.position = "static";
          } else {
            cookieConsent.style.position = "fixed";
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: "100px",
      },
    );
    observer.observe(document.querySelector("#bottom-marker"));
  }

});
